export const baseTheme = {
  colors: {
    background: "#24262D",
    text: "#c8c8c9",
    subtext: "#5b5c5e",
    pink: "#4078c0",
    green: "#85bb65",
    blue: "#00acee",
    tyellow: "#E9BC3F",
    yellow: "#5865F2",
  },
};
