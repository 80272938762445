interface Config {
  [key: string]: any;
}

export const config: Config = {
  links: {
    twitter: "https://twitter.com/itsjtech",
    linkedin:
      "https://www.linkedin.com/in/julian-o%E2%80%99shaughnessy-7b5a3616a/",
    github: "https://github.com/julianoshaughnessy",
    wallet: "",
    ipfs: "https://ipfs.io/",
  },

  discord: "jeotech",

  version: process.env.REACT_APP_VERSION,

  env: process.env.REACT_APP_ENV,
};
